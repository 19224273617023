export enum PlaceAdPlansPrices {
	FEATURED_AD_PRICE_RENT_RESIDENTIAL = 99.5,
	STANDARD_AD_PRICE_RENT_RESIDENTIAL = 49.5,

	FEATURED_AD_PRICE_RENT_COMMERCIAL = 199.5,
	STANDARD_AD_PRICE_RENT_COMMERCIAL = 79.5,

	FEATURED_AD_PRICE_RENT_SHARE_RESIDENTIAL = 19.5,
	STANDARD_AD_PRICE_RENT_SHARE_RESIDENTIAL = 9.5,
}

export enum PlaceAdType {
	SECTION = 'sectionId',
	AISLE = 'aisleId',
	STAND = 'standId',
	STANDS = 'stand',
	SHELF = 'shelfId',
	COUNTY = 'countyId',
	COUNTY_AREA = 'countyAreaId',
	SELLER_TYPE = 'sellerTypeId',
	ADDRESS = 'address',
	PRICE = 'price',
	IS_PRICE_ON_APPLICATION = 'isPriceOnApplication',
	BATHROOM_COUNT = 'bathroomCount',
	BEDROOM_COUNT = 'bedroomCount',
	PARKING_ACCESS = 'parkingAccessId',
	PARKING_COUNT = 'parkingAvailableSpaceCount',
	SINGLE_BEDROOM_COUNT = 'singleBedroomCount',
	DOUBLE_BEDROOM_COUNT = 'doubleBedroomCount',
	SUITE_BEDROOM_COUNT = 'suiteBedroomCount',
	FURNISHING = 'furnishingId',
	PROPERTY_SIZE = 'propertySize',
	PROPERTY_EXTRAS = 'propertyExtras',
	RENTAL_PERIOD = 'rentalPeriodId',
	LEASE_TERM = 'leaseTermId',
	AVAILABLE_FROM = 'availableFrom',
	DESCRIPTION = 'description',
	YOUTUBE_VIDEO_URL = 'youtubeVideoUrl',
	IS_DRAFT = 'isDraft',
	OWNER_OCCUPIED = 'isOwnerOccupied',
	TEANANT_PREFERENCE = 'tenantPreferenceId',
	ONEPLUS_PERSON_ALLOWED = 'isOnePlusPersonAllowed',
	OTHER_TENANTS = 'otherTenantShareCount',
	PROPERTY_UNIT = 'propertyUnitId',
	BER_TYPE = 'berId',
	BER_NUMBER = 'berNumber',
	TAX_DESIGNATION = 'taxDesignationsId',
	AGENT = 'agentId',
}

export enum PlaceAdValuesEndPoint {
	COUNTY = 'county',
	COUNTY_AREA = 'county-area',
	STAND = 'stands',
	RENTAL_PERIOD = 'rental-periods',
	LEASE_TERM = 'lease-terms',
	FURNISHING = 'furnishings',
	BEDROOM_COUNT = 'bedrooms',
	BATHROOM_COUNT = 'bathrooms',
	PROPERTY_EXTRAS = 'property-extras',
	OTHER_TENANTS_COUNT = 'tenants',
	TENANT_PREFERENCES = 'tenant-preferences',
	PROPERTY_UNITS = 'property-units',
	BER = 'ber',
	TAX_DESIGNATIONS = 'tax-designations',
}

export enum PlaceAdDataStatus {
	NOT_CREATED = 0,
	UPDATED = 1,
	UPDATED_AGAIN = 2,
}

export enum PlaceAdPlanTypes {
	FEATURED = 'FEATURED',
	STANDARD = 'STANDARD',
}

export enum AdPaymentStatus {
	PAID = 'paid',
	UNPAID = 'unpaid',
}

export enum AdStatus {
	LIVE = 'live',
	SOLD = 'sold',
	UNDER_REVIEW = 'under-review',
	DRAFT = 'draft',
	PAYMENT_PENDING = 'payment-pending',
}

export enum PlaceAdPlansId {
	STANDARD_AD = '1',
	FEATURED_AD = '2',
	BRANDED_STANDARD = '3',
	BRANDED_FEATURED = '4',
	BRANDED_PLUS = '5',
}

export enum PlaceAdTabIndex {
	TAB_0 = 0,
	TAB_1,
	TAB_2,
	TAB_3,
	TAB_4,
	TAB_5,
	TAB_6,
	TAB_7,
	TAB_8,
	TAB_9,
}

export enum PlaceAdTotalTabs {
	PROPERTIES_FOR_SALE_TOTAL_TAB = 8,
	PROPERTIES_FOR_RENT_TOTAL_TAB = 8,
}

export enum AdSubmittedPlatform {
	WEB = 'web',
	MOBILE = 'mobile',
}
