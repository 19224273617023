export const DEFAULT_META_CONTENTS = {
	title: 'n3233',
	type: 'website',
	image: '/assets/images/website-og-cover-photo.webp',
	siteName: 'FindQo.ie',
};

export const HOME_META_CONTENTS = {
	description:
		'FindQo.ie: Buy, sell, or rent properties in Ireland. Find houses, apartments, land, offices and more from trusted agents and landlords. Free for all users.',
};

export const ABOUT_META_CONTENTS = {
	description: `FindQo.ie engineering team, a team of 31 highly skilled Software Engineers, designers, and Solution Architects with the mission to address Ireland's unique property rental challenges mixed with the latest tech.`,
};

export const AGENT_HUB_META_CONTENTS = {
	description: `Ireland's Freshest Property Rental Platform is made for Agents, Landlords and tenants.`,
};

export const SELLER_META_CONTENTS = {
	description: (seller: string, address: string) => `${seller} properties in ${address}`,
};

export const CONTACT_US_SEO = {
	description: `Contact FindQo.ie for your property needs in Ireland. Call +353-15314791 or visit us at Suite 5, Plaza 256, Blanchardstown Corporate Park 2, Dublin 15.`,
};
