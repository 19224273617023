import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ClientUser } from '@core/models';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	constructor(@Inject(PLATFORM_ID) private platformId: string) {}

	private _isIntercomMessengerOpen$ = new BehaviorSubject<boolean>(false);

	//show intercom messenger
	showIntercom(): void {
		if (window?.Intercom && isPlatformBrowser(this.platformId)) {
			window.Intercom('boot', {
				app_id: environment.intercomId,
				hide_default_launcher: false,
			});

			window.Intercom('show');

			this._isIntercomMessengerOpen$.next(true);
		}
	}

	shutdownIntercom(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window?.Intercom) {
				window.Intercom('shutdown');
			}
		}
	}

	bootIntercom(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window?.Intercom) {
				//create  a new session
				window.Intercom('boot', {
					app_id: environment.intercomId,
					hide_default_launcher: false,
				});
			}
		}
	}

	// add user to intercom
	addIntercomUser(user: ClientUser): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window.Intercom) {
				window.Intercom('boot', {
					app_id: environment.intercomId,
					name: user?.name,
					user_id: user?.id,
					email: user?.email,
					created_at: user?.createdAt,
					hide_default_launcher: true,
				});
			}
		}
	}

	// update user data in intercom
	updateIntercomUser(user: ClientUser): void {
		if (isPlatformBrowser(this.platformId) && window?.Intercom) {
			window.Intercom('update', {
				app_id: environment.intercomId,
				user_id: user?.id,
				email: user?.email,
				name: user?.name,
				hide_default_launcher: true,
			});
		}
	}

	// update user's latest ad id in intercom
	updateLastPostAd(adId: string, user: ClientUser): void {
		if (isPlatformBrowser(this.platformId) && window?.Intercom) {
			window.Intercom('update', {
				app_id: environment.intercomId,
				user_id: user?.id,
				email: user?.email,
				name: user?.name,
				last_post_ad: adId,
				hide_default_launcher: true,
			});
		}
	}

	toggelIntercomPopup(hide: boolean): void {
		if (isPlatformBrowser(this.platformId) && window?.Intercom) {
			window?.Intercom('update', {
				hide_default_launcher: hide,
			});
		}
	}
}
