import { SellerType } from '@core/enums';

export enum SellerTypeSlug {
	INDIVIDUAL = 'individual',
	BUSINESS = 'business',
}

export const APP_ROUTE = {
	home: '/',
	myAdList: 'my-ads',
	myAdmatchingTenants: 'matching-tenants/:adId',
	myAdmatchingTenantsId: (adId: string) => `matching-tenants/${adId}`,
	myProfile: 'profile',
	businessProfile: {
		base: 'business',
		edit: 'business/edit',
		welcome: 'business/welcome',
		agentList: 'business/agents',
		subscription: 'business/subscription',
		paymentPlan: 'business/payment-plans',
		paymentSuccess: 'business/payment-success',
	},
	meetingSchedule: {
		base: 'meeting-schedule',
		add: 'create',
		edit: 'edit',
		inactive: 'meetings/inactive',
	},
	placeAd: 'place-ad',
	editAd: 'edit-ad',
	placeAdCategory: 'place-ad/category', //Need to finalize after confirming the flow from management side
	placeAdSection: 'place-ad/category/:section',
	placeAdSteps: 'place-ad/category/:section/:aisle',
	placeAdComplete: 'ad-submitted',
	terms: 'terms',
	privacyPolicy: 'privacy-policy',
	cookiePolicy: 'cookie-policy',
	about: 'about',
	agent: 'agent-hub',
	favorites: 'favorites',
	playground: 'playground',
	adList: ':section/:location/:aisle',
	pricing: 'pricing',
	contact: 'contact',
	placeAdGuide: 'advertise-your-property',
	emailInvitation: 'email-invitation',
	propertyAdDetail: (adId?: string, title?: string) => (!!adId ? `property/${adId}/${title}` : 'property'),
	myEnquiries: 'my-enquiries',
	placeAdBusiness: 'place-ad/business-account',
	unSubscribeEmail: 'unsubscribe',
	wildCard: '**',
	seller: (sellerId?: string, sellerName?: string, sellerType?: string) =>
		!!sellerId ? `agent/${sellerType === SellerType.INDIVIDUAL ? SellerTypeSlug.INDIVIDUAL : SellerTypeSlug.BUSINESS}/${sellerId}/${sellerName}` : 'agent',
	agentAds: (businessId: string, businessName: string) => `agent/business/${businessId}/${businessName}`,
	apps: 'apps',

	// External routes
	external: {
		googlePlayStoreApp: 'https://play.google.com/store/apps/details?id=com.findqo.app',
		appStoreApp: 'https://apps.apple.com/us/app/findqo-irish-property-platform/id6449600854',
		huaweiStoreApp: 'https://appgallery.huawei.com/app/C109420795',
	},

	// Public routes
	public: {
		base: 'public',
		seller: (sellerId?: string, sellerName?: string, sellerType?: string) =>
			!!sellerId
				? `public/agent/${sellerType === SellerType.INDIVIDUAL ? SellerTypeSlug.INDIVIDUAL : SellerTypeSlug.BUSINESS}/${sellerId}/${sellerName}`
				: 'agent',
		propertyAdDetail: 'property',
	},
};

//We will use this in routing modules only
export const ROUTE_PATH = {
	placeAd: { category: 'category', section: 'category/:section', steps: 'category/:section/:aisle', businessAccount: 'business-account' },
};
